var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"k-9sptci"},[_c('el-table',{staticStyle:{"margin-top":"20px"},attrs:{"data":_vm.dataTable,"border":""}},[_vm._l((_vm.dataList),function(item){return [_c('el-table-column',{key:item._id,attrs:{"prop":item.prop,"type":item.type,"label":item.label,"width":item.width,"formatter":item.formatter}})]}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('perButton',{attrs:{"perm":`match:stage`},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleChangeScore(scope.row)}}},[_vm._v("修改积分")]),_c('perButton',{attrs:{"perm":`match:stage`},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleChangeSort(scope.row)}}},[_vm._v("调整排序")])]}}])})],2),(_vm.scoreChangeVisible)?_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":'修改积分',"visible":_vm.scoreChangeVisible,"close-on-click-modal":false,"width":"400px"},on:{"update:visible":function($event){_vm.scoreChangeVisible=$event}}},[_c('el-form',{ref:"scoreForm",attrs:{"model":_vm.scoreChangeData,"label-position":"right","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"赛事："}},[_vm._v(" "+_vm._s(_vm.match_types[_vm.type_id])+" ")]),_c('el-form-item',{attrs:{"label":"玩家uid："}},[_vm._v(" "+_vm._s(_vm.scoreChangeData.uid)+" ")]),_c('el-form-item',{attrs:{"label":"玩家昵称："}},[_vm._v(" "+_vm._s(_vm.scoreChangeData.name)+" ")]),_c('el-form-item',{attrs:{"label":"当前积分："}},[_vm._v(" "+_vm._s(_vm.scoreChangeData.score)+" ")]),_c('el-form-item',{attrs:{"label":"修改为：","prop":"new_score","label-position":"left","rules":[
          {
            required: true,
            message: '请输入积分',
            trigger: ['blur', 'change'],
          },
          {
            validator: _vm.scoreInteger,
            message: '请输入大于等于0的整数或2位小数',
            trigger: ['change', 'blur'],
          },
        ]}},[_c('el-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"请输入积分"},model:{value:(_vm.scoreChangeData.new_score),callback:function ($$v) {_vm.$set(_vm.scoreChangeData, "new_score", $$v)},expression:"scoreChangeData.new_score"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveScore}},[_vm._v("确定")]),_c('el-button',{on:{"click":function($event){_vm.scoreChangeVisible = false}}},[_vm._v("取消")])],1)],1)],1):_vm._e(),(_vm.sortChangeVisible)?_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":'修改同分排序',"visible":_vm.sortChangeVisible,"close-on-click-modal":false,"width":"400px"},on:{"update:visible":function($event){_vm.sortChangeVisible=$event}}},[_c('el-form',{ref:"sortForm",attrs:{"model":_vm.sortChangeData,"label-position":"right","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"赛事："}},[_vm._v(" "+_vm._s(_vm.match_types[_vm.type_id])+" ")]),_c('el-form-item',{attrs:{"label":"玩家uid"}},[_vm._v(" "+_vm._s(_vm.sortChangeData.uid)+" ")]),_c('el-form-item',{attrs:{"label":"玩家昵称："}},[_vm._v(" "+_vm._s(_vm.scoreChangeData.name)+" ")]),_c('el-form-item',{attrs:{"label":"当前积分："}},[_vm._v(" "+_vm._s(_vm.scoreChangeData.score)+" ")]),_c('el-form-item',{attrs:{"label":"排序：","prop":"sort","label-position":"left","rules":[
          {
            required: true,
            message: '请输入排序',
            trigger: ['blur', 'change'],
          },
          {
            validator: _vm.sortInteger,
            message: '请输入整数',
            trigger: ['change', 'blur'],
          },
        ]}},[_c('el-input',{staticStyle:{"width":"150px"},attrs:{"type":"number","placeholder":"请输入排序","maxlength":"4"},model:{value:(_vm.sortChangeData.sort),callback:function ($$v) {_vm.$set(_vm.sortChangeData, "sort", $$v)},expression:"sortChangeData.sort"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveSort}},[_vm._v("确定")]),_c('el-button',{on:{"click":function($event){_vm.sortChangeVisible = false}}},[_vm._v("取消")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }