<template>
  <div>
    <h4 class="season_title">
      {{ match_data.match_name }}{{ season_name }}·排行榜
    </h4>
    <el-tabs v-model="rankName" type="card" @tab-click="handleClick">
      <div class="match-head">
        <el-form
          :inline="true"
          class="k-9sptci-header"
          :model="searchForm"
          ref="searchForm"
        >
          <!-- 预选赛 -->
          <el-form-item
            label="积分总榜"
            prop="group"
            v-if="season_name == '预选赛' && rankName == 'team_rank'"
          >
            <el-select
              v-model="searchForm.group"
              placeholder="请选择"
              clearable
              @change="handleQuery"
            >
              <el-option value="" label="积分总榜"></el-option>
              <el-option
                v-for="(item, index) in takeData"
                :value="item.group"
                :label="`${item.group}组`"
                :key="index"
              ></el-option>
              <el-option value="0" label="替补组"></el-option>
            </el-select>
          </el-form-item>
          <!-- 周 -->
          <el-form-item
            label="周"
            prop="stage"
            v-if="season_name == '预选赛' && rankName == 'team_rank'"
          >
            <el-select
              v-model="searchForm.stage"
              placeholder="请选择"
              @change="handleQuery"
            >
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="n in Math.min(weeks.length, 3)"
                :key="n"
                :value="n.toString()"
                :label="`第${n}周`"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- / 预选赛 -->
          <!-- 常规赛 -->
          <el-form-item
            label="轮"
            prop="group"
            v-if="
              season_name == '常规赛' &&
              match_type == 'match_s4' &&
              rankName == 'team_rank'
            "
          >
            <el-select
              v-model="searchForm.group"
              placeholder="请选择"
              clearable
              @change="handleQuery"
            >
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="(item, index) in wheel_item"
                :value="item.name"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 组 -->
          <el-form-item
            label="组"
            prop="stage"
            v-if="
              season_name == '常规赛' &&
              match_type == 'match_s4' &&
              rankName == 'team_rank'
            "
          >
            <el-select
              v-model="searchForm.stage"
              placeholder="请选择"
              @change="handleQuery"
            >
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="(item, index) in group_item"
                :value="item.group"
                :label="`${item.group_name}`"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- /常规赛 -->
          <!-- 分组 -->
          <el-form-item
            label="分组"
            prop="group_id"
            v-if="newGroupInfo.length != 0 && rankName == 'team_rank'"
          >
            <el-select
              v-model="searchForm.group_id"
              placeholder="请选择"
              @change="handleQuery"
            >
              <el-option value="" label="全部"></el-option>
              <el-option
                v-for="item in newGroupInfo"
                :value="item._id"
                :label="item.group_name"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="门派ID"
            prop="sect_id"
            v-if="rankName == 'team_rank'"
          >
            <el-input
              v-model="searchForm.sect_id"
              clearable
              placeholder="门派ID"
              maxlength="6"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="门派名称"
            prop="sect_name"
            v-if="rankName == 'team_rank'"
          >
            <el-input
              v-model="searchForm.sect_name"
              clearable
              placeholder="门派名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="UID"
            prop="uid"
            v-if="rankName == 'personal_rank'"
          >
            <el-input
              v-model="searchForm.uid"
              clearable
              placeholder="玩家UID"
              maxlength="10"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="玩家昵称"
            prop="name"
            v-if="rankName == 'personal_rank'"
          >
            <el-input
              v-model="searchForm.name"
              clearable
              placeholder="玩家昵称"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="handleClick">查询</el-button>

            <el-button type="primary" @click="publishRank">发布排名</el-button>
          </el-form-item>
        </el-form>
        <div>
          <div class="match_name">
            <el-form :inline="true" ref="searchForm" style="position: relative">
              <score_manage :type_id_s4="type_id" :tab="tab"></score_manage>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 门派 -->
      <el-tab-pane label="门派" name="team_rank" class="upward-shift">
        <s1_team_rank
          ref="teamRank"
          :match_id="match_id"
          :dataTable.sync="dataTableTeam"
          :season_name="season_name"
          @updateQuery="handleClickQuery"
        ></s1_team_rank>
        <div class="k-9sptci-footer">
          <el-pagination
            @current-change="handleQueryCurrent"
            @size-change="handleSizeChange"
            :page-sizes="pagesizes"
            :page-size="page_size"
            :current-page.sync="pager.pageNum"
            layout="sizes, prev, pager, next"
            :total="pager.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <!-- 个人 -->
      <el-tab-pane
        label="个人"
        name="personal_rank"
        v-if="showPersonTabPane"
        class="upward-shift2"
      >
        <s1_personal_rank
          :match_id="match_id"
          :dataTable="dataTableTeam"
          :season_name="season_name"
          @updateQuery="handleClickQuery"
        ></s1_personal_rank>
        <el-pagination
          @current-change="handleQueryCurrent"
          @size-change="handleSizeChange"
          :page-sizes="pagesizes"
          :page-size="page_size"
          :current-page.sync="pager.pageNum"
          layout="sizes, prev, pager, next"
          :total="pager.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MessageBox } from "element-ui";
// 门派
import s1_team_rank from "./components/rank/team_rank.vue";
//个人排名
import s1_personal_rank from "./components/rank/personal_rank.vue";
// 模板下载
import score_manage from "./components/rank/scoreManage.vue";
export default {
  name: "s1_rank",
  components: { s1_team_rank, s1_personal_rank, score_manage },
  computed: {
    ...mapState("match", ["match_types"]),
    //是否有个人的按钮
    showPersonTabPane() {
      let tempTypeIsd = [];
      if (tempTypeIsd.indexOf(this.type_id) > -1) {
        return false;
      } else {
        return true;
      }
    },
    page_size() {
      if (this.type_id === "qualifier") {
        return 12;
      } else {
        return 10;
      }
    },
    pagesizes() {
      if (this.type_id === "qualifier") {
        return [12];
      } else {
        return [10];
      }
    },
  },
  data() {
    return {
      wheel_item: [], //积分总榜数据
      group_item: [], //组的数据
      weeks: [],
      takeData: [],
      match_data: {},
      newGroupInfo: [],
      pageSize: 10,
      searchForm: {
        group: "",
        stage: "",
        group_id: "",
        sect_id: "",
        sect_name: "",
        uid: "",
        name: "",
      },
      pager: {
        total: 0,
        pageNum: 1,
      },
      tab: 0,

      dataTable: [],
      dataTableTeam: [],
      dataTableTeamPager: {},
      rankName: "team_rank",
      match_id: "",
      stage_s4: "",
      type_id: "",
      season_name: "",
      match_type: "",
    };
  },
  watch: {},
  mounted() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_data = JSON.parse(localStorage.getItem("match_segment"));
    this.season_name = this.$route.query.season_name;
    this.match_id = this.$route.query.match_id;
    this.type_id = this.$route.query.type_id;
    this.match_type = this.$route.query.match_type;
    if (this.$route.query.season_id != undefined) {
      this.searchForm.season_id = this.$route.query.season_id;
    }
    //分组
    this.takeData = Math.floor(Number(this.$route.query.take) / 10);
    if (this.$route.query.stage) {
      this.stage = this.$route.query.stage;
    }
    //目前分常规赛 淘汰赛第一轮  淘汰赛第二轮
    if (
      this.season_name == "常规赛" ||
      this.$route.query.type_id == "elimination1" ||
      this.$route.query.type_id == "elimination2"
    ) {
      this.getGruopList();
    } else {
      this.handleQuery();
    }
    if (this.season_name == "常规赛" && this.match_type == "match_s3") {
      this.handleQuery();
    }

    this.$bus.$on("onRefushList", (msg) => {
      if (msg == "person") {
        this.handleQueryPerson();
      } else if (msg == "team") {
        this.handleQuery();
      }
    });
    if (this.match_type == "match_s4") {
      this.get_stage_list();
      this.queryteamSelect();
      this.handleQuery();
    }
  },
  methods: {
    // 获取每周赛事,tab
    async get_stage_list() {
      const res = await this.$http.match.s4_stage_list({
        match_id: this.match_id,
        season_id: this.searchForm.season_id,
      });
      if (this.type_id === "qualifier") {
         for (let i = 1; i <= res.data.data.length; i++) {
        this.weeks.push({ label: `第${i}周`, name: `${i}` });
      }
      }
      if (this.type_id === "regular") {
          for (let i = 0; i < res.data.data.length; i++) {
        this.wheel_item.push({  label: res.data.data[i].name,
    name: res.data.data[i].stage });
      }
      }  
      
    },
    //s4积分总榜下拉框内容
    async queryteamSelect() {
      const { data } = await this.$http.match.s4_match_season_all({
        stage: this.searchForm.stage,
        season_id: this.searchForm.season_id,
        match_id: this.match_id,
      });
      this.takeData = data.group;
      // this.wheel_item = data.group;
      // console.log("🚀 ~ queryteamSelect ~  this.wheel_item :",  this.wheel_item )
     
      this.group_item = data.group;
      console.log("🚀 ~ queryteamSelect ~ group_item:", this.group_item);
      
    },
    //
    async handleQueryCurrent(val) {
      this.pager.pageNum = val;
      this.pager.total = 0;
      if (this.rankName == "team_rank") {
        this.handleQuery();
      } else {
        this.handleQueryPerson();
      }
    },
    //
    async handleSizeChange(val) {
      this.pageSize = val;
      console.log(
        "🚀 ~ file: rank.vue:406 ~ handleSizeChange ~ this.pageSize:",
        this.pageSize
      );
      this.pager.pageNum = 1;
      this.pager.total = 0;
      if (this.rankName == "team_rank") {
        this.handleQuery();
      } else {
        this.handleQueryPerson();
      }
    },
    //查询分组列表,s3用的wpl的接口
    async getGruopList() {
      if (this.season_name == "常规赛" && this.match_type != "match_s3") {
        //请求接口
        const { data, errorCode } = await this.$http.match.match_season_all({
          match_id: this.match_id,
        });
        if (data) {
          data.forEach((item) => {
            if (item.season_name == "常规赛") {
              this.newGroupInfo = item.group;
              if (item.group && item.group.length != 0) {
                this.searchForm.group_id = item.group[0]._id;
                this.handleQuery();
              }
            }
          });
        }
      } else {
        //wpl请求接口
        const { data, errorCode } =
          await this.$http.match_subject.new_match_season_all({
            match_id: this.match_id,
            season_id: this.searchForm.season_id,
          });
        let new_group = data.group;
        if (new_group) {
          this.newGroupInfo = new_group;
          if (new_group && new_group.length != 0) {
            //默认显示第一个
            // this.searchForm.group_id = new_group[0]._id;
            this.handleQuery();
          }
        }
      }
    },
    //查询列表
    async handleQuery() {
      let stage = "";
      let group = "";
      let group_id = "";
      if (this.type_id === "qualifier") {
        this.pageSize = 12;
      }
      if (this.match_type == "match_s4" && this.type_id === "qualifier") {
        if (
          this.wheel_item.length > 0 &&
          this.searchForm.group != 0 &&
          this.searchForm.group != ""
        ) {
          let num = this.searchForm.group;
          group_id = this.wheel_item[num - 1]._id;
          stage = this.searchForm.stage;
        } else {
          group_id =
            this.searchForm.group === 0
              ? 0
              : this.searchForm.group
              ? this.searchForm.group
              : "";
          stage = this.searchForm.stage;
        }
        this.queryteamSelect();
      } else if (
        this.match_type == "match_s4" &&
        this.type_id === "regular"
      ) {
        stage = this.searchForm.group
        let num = this.searchForm.stage;
         if (
          this.group_item.length > 0 &&
          this.searchForm.stage != 0 &&
          this.searchForm.stage != ""
        ) {
          group_id =  this.group_item[num - 1]._id 
        }else {
          group_id = ""
        }
      } else {
        group_id = this.searchForm.group_id;
        group = this.searchForm.group;
        stage = this.searchForm.stage;
      }
      const { data, errorCode } =
        await this.$http.match_subject.get_team_subject_rank({
          match_id: this.match_id, //
          group: group, //预选赛时传，分组，默认不传，1-20组，替补组传0
          group_id: group_id, //常规赛传 分组id
          type_id: this.type_id, //
          season_id: this.searchForm.season_id,
          stage: stage, //阶段，默认查总的不传，1，第一周，2第二周 3 第三周
          sect_id: this.searchForm.sect_id,
          sect_name: this.searchForm.sect_name,
          pageNum: this.pager.pageNum,
          pageSize: this.pageSize,
        });
      //
      this.dataTableTeam = data.data;
      this.pager = data.pager;
    },

    //查询列表个人
    async handleQueryPerson() {
      //请求接口
      const { data, errorCode } =
        await this.$http.match_subject.get_team_subject_member_rank({
          match_id: this.match_id,
          group: this.searchForm.group, //预选赛时传，分组，默认不传，1-20组，替补组传0
          // group_id: this.searchForm.group_id, //常规赛传 分组id
          type_id: this.type_id,
          season_id: this.searchForm.season_id,
          stage: this.searchForm.stage, //阶段，默认查总的不传，1，第一周，2第二周 3 第三周
          uid: this.searchForm.uid,
          name: this.searchForm.name,
          pageNum: this.pager.pageNum,
          pageSize: this.pageSize,
        });
      this.dataTableTeam = data.data;
      this.pager = data.pager;
    },
    // 发布
    async publishRank() {
      try {
        let confirm = await MessageBox.confirm(
          "发布排名后将更新小程序所有门派排名信息，是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );

        if (confirm != "confirm") {
          return;
        }

        this.loading = true;
        if (this.searchForm.season_id != "") {
          let params = {
            match_id: this.match_id,
            season_id: this.searchForm.season_id,
          };

          let { data, errorCode } = await this.$http.match.publish_rank(params);
          this.loading = false;
          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "发布成功",
          });
        } else {
          let params = {
            match_id: this.match_id,
          };

          let { data, errorCode } = await this.$http.match.publish_rank(params);
          this.loading = false;
          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "发布成功",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    //切换事件
    handleClick(tab) {
      if (tab.index === "0") {
        this.tab = 1;
      }
      if (tab.index === "1") {
        this.tab = 0;
      }
      this.pager.pageNum = 1;
      this.handleClickQuery();
    },
    handleClickQuery() {
      if (this.rankName == "team_rank") {
        this.handleQuery();
      } else {
        this.handleQueryPerson();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.rank_mannge {
  position: absolute;
  right: 630px;
  top: 50px;
  z-index: 99;
}
.match_name {
  position: relative;
  transform: translateY(-32px);
  font-size: 14px;
  height: 50px;
  z-index: 1000;
}
.match-head {
  padding-left: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  background-color: rgb(255, 255, 255);
}
.k-9sptci-header {
  width: 74%;
}
.season_title {
  position: relative;
  margin: 10px;
  margin-bottom: 15px;
}
.upward-shift {
  margin-top: -50px;
}
.upward-shift2 {
  margin-top: -25px;
}

/deep/ .el-tabs__header {
  margin: 0;
  border: 0;
}
/deep/.el-tabs__content {
  border: 1px solid #e4e7ed;
}
.group,
.week {
  position: absolute;
  top: 98px;
  z-index: 1000;
}
.group {
  left: 175px;
}
.week {
  left: 350px;
}
/deep/ .el-select--small {
  width: 150px;
}
</style>
