<template>
  <div>
    <!-- 个人积分 -->
    <div
      style="position: absolute; height: 100%; right: 300px; top:0"
      v-if="isShowPerson && tab === 0 "
    >
      <fieldset
        style="border-color: rgb(200,200,200); border-width: 1px; border-style: solid; padding: 0 20px 5px 20px; box-sizing: border-box"
      >
        <legend style="color: #777777; height: 20px; font-size: 12px; ">
          {{title}}个人积分
        </legend>
        <div style="display: flex" v-if="type_id != undefined">
          <importButton
            :import-url="`/admin/match/${type_id}/import_member_score`"
            :status-url="`/admin/match/${type_id}/import_member_score_status`"
            :data="{ match_id: match_id, season_id: season_id }"
            @refushList="refushList('person')"
          ></importButton>
          <!-- import_template使用s1模板是因为模板通用，不需要区分 -->
          <importHistoryButton
            :history_url="`/admin/match/${type_id}/import_member_score_history`"
            :data="{ match_id: match_id, season_id: season_id }"
            :import_template="`import_s1_member_score_league`"
          ></importHistoryButton>
        </div>
      </fieldset>
    </div>
    <!-- 门派 -->
    <div 
    v-if="tab === 1"
    style="position: absolute; height: 100%; right: 0; top:0">
      <fieldset
        style="border-color: rgb(200,200,200); border-width: 1px; border-style: solid; padding: 0 20px 5px 20px; box-sizing: border-box"
      >
        <legend style="color: #777777; height: 20px; font-size: 12px; ">
         {{title}} 门派积分
        </legend>
        <div style="display: flex">
          <importButton
            :import-url="`/admin/match/${type_id}/import_team_score`"
            :status-url="`/admin/match/${type_id}/import_team_score_status`"
            :data="{ match_id: match_id, season_id: season_id }"
            @refushList="refushList('team')"
          ></importButton>
          <!-- import_template使用s1模板是因为模板通用，不需要区分 -->
          <importHistoryButton
            :history_url="`/admin/match/${type_id}/import_team_score_history`"
            :data="{ match_id: match_id, season_id: season_id }"
            :import_template="`import_s1_team_score_league`"
          ></importHistoryButton>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<script>
import importButton from "@/view/content/match/components/importButton";
import importHistoryButton from "@/view/content/match/components/importHistoryButton";
export default {
  name:'rank_download',
  props :{
    type_id_s4: {
      type: String,
    },
     tab: {
      type: Number,
    },
  },
  
  data() {
    return {
      type_id: "",
      match_id: "",
      season_id: "",
      new_type_id: "",
    };
  },
  computed: {
    isShowPerson() {
      let temp_persons = [
        "audition",
        "elimination1",
        "elimination2",
        "resurrection",
      ];
      //如果找到了，隐藏
      if (temp_persons.indexOf(this.new_type_id) > -1) {
        return false;
      } else {
        return true;
      }
    },
      title() {
      if (this.type_id_s4 === 'qualifier') {
        return '预选赛';
      } else if (this.type_id_s4 === 'final') {
        return '总决赛';
      } else if (this.type_id_s4 === 'regular') {
        return '常规赛';
      } else {
        return '';
      }
    },
  },
  watch: {
    $route: {
      handler: function(val) {
        let tempTypes = [
          "audition",
          "elimination1",
          "elimination2",
          "resurrection",
          "wpl_finals",
        ];
        // 接口s3常规赛，总决赛用wpl的type_id
        if (tempTypes.indexOf(val.query.type_id) > -1  || val.query.type_id == 'regular' || val.query.type_id == 'final'  ) {
          this.type_id = "wpl";
        } else {
          this.type_id = val.query.type_id;
        }
        //是否显示个人导入
        this.new_type_id = val.query.type_id;
        this.match_id = val.query.match_id;
        this.season_id = val.query.season_id;
      },
      immediate: true,
    },
  },
  components: {
    importButton,
    importHistoryButton,
  },
  methods: {
    refushList(type) {
      this.$bus.$emit("onRefushList", type);
    },
  },
};
</script>
<style></style>
